import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';

import ProductImage, { StyledImage } from './ProductImage';
import { breakpoints, spacing } from '../../utils/styles';

const ProductImagesMobileRoot = styled(`div`)`
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
  padding: ${spacing.md}px;
  padding-bottom: ${spacing.xs}px;
  width: 100%;

  @media (min-width: ${breakpoints.desktop}px) {
    width: 36rem;
  }
`;

const ProductImagesMobileContent = styled(`div`)`
  display: inline-flex;

  ${StyledImage} {
    flex-shrink: 0;
    margin-right: ${spacing.md}px;
    width: 50vw;
  }
`;

const ProductImagesMobile = ({ images, imageOnClick }) => (
  <ProductImagesMobileRoot>
    <ProductImagesMobileContent>
      {images.map((image, idx) => (
        <ProductImage key={idx} image={image} onClick={imageOnClick} />
      ))}
    </ProductImagesMobileContent>
  </ProductImagesMobileRoot>
);

ProductImagesMobile.propTypes = {
  images: PropTypes.array.isRequired,
  imageOnClick: PropTypes.func,
};

export default ProductImagesMobile;
