import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import React, { Component } from 'react';

import ProductForm from './ProductForm';
import ProductImagesDesktop from './ProductImagesDesktop';
import ProductImagesMobile from './ProductImagesMobile';
import ProductSpecs from './ProductSpecs';
import ShippingNotice from '../shared/ShippingNotice';
import { breakpoints, spacing } from '../../utils/styles';

const ProductPageRoot = styled('div')`
  padding-bottom: ${spacing.md}px;

  @media (min-width: ${breakpoints.desktop}px) {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 477px);
    padding: ${spacing.xl}px;
    width: 100%;
  }
`;

const Container = styled(`div`)`
  @media (min-width: ${breakpoints.desktop}px) {
    align-items: flex-start;
    display: flex;
  }
`;

const Details = styled(`div`)`
  position: relative;

  @media (min-width: ${breakpoints.desktop}px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: -${spacing.xl}px;
    max-width: 600px;
    min-height: 490px;
  }
`;

class ProductPage extends Component {
  componentDidMount() {
    const images = this.props.product.images;
    this.props.setCurrentProductImages(images);
    document.getElementById('shippingNotice').scrollIntoView();
  }

  render() {
    const {
      product,
      product: { id, images },
    } = this.props;

    const { isDesktopViewport, productImageFeatured, toggleProductImagesBrowser } = this.props;

    return (
      <React.Fragment>
        <ShippingNotice id="shippingNotice" />
        <ProductPageRoot>
          <Container>
            {isDesktopViewport ? (
              <ProductImagesDesktop
                images={images}
                imageOnClick={toggleProductImagesBrowser}
                imageFeatured={productImageFeatured}
              />
            ) : (
              <ProductImagesMobile images={images} imageOnClick={toggleProductImagesBrowser} />
            )}
            <Details>
              <ProductSpecs product={product} />
              <ProductForm id={id} variants={product.variants} />
            </Details>
          </Container>
        </ProductPageRoot>
      </React.Fragment>
    );
  }
}

ProductPage.propTypes = {
  isDesktopViewport: PropTypes.bool,
  product: PropTypes.object.isRequired,
  productImageFeatured: PropTypes.object,
  productImagesBrowserStatus: PropTypes.string.isRequired,
  setCurrentProductImages: PropTypes.func.isRequired,
  toggleProductImagesBrowser: PropTypes.func.isRequired,
};

export default ProductPage;
