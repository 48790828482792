/** @jsx jsx */

import PropTypes from 'prop-types';
import { jsx } from 'theme-ui';

import ProductImage from './ProductImage';
import ProductThumbnails, { Thumbnail } from './ProductThumbnails';

const THUMBNAIL_SIZE = '54px';

const ProductImagesDesktop = ({ images, imageFeatured, imageOnClick }) => {
  const image = images[0];

  return (
    <div sx={{ width: '36rem', mr: 2 }}>
      <ProductImage image={imageFeatured || image} onClick={imageOnClick} />
      <ProductThumbnails
        sx={{ [Thumbnail]: { width: THUMBNAIL_SIZE, height: THUMBNAIL_SIZE } }}
        images={images}
      />
    </div>
  );
};

ProductImagesDesktop.propTypes = {
  images: PropTypes.array.isRequired,
  imageOnClick: PropTypes.func,
  imageFeatured: PropTypes.object,
};

export default ProductImagesDesktop;
