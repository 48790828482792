import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { object } from 'prop-types';

import InterfaceContext from '../context/InterfaceContext';
import ProductPage from '../components/ProductPage';

const ProductPageTemplate = (props) => {
  const {
    data: {
      site: {
        siteMetadata: { siteUrl },
      },
      mdx: { frontmatter },
      shopifyProduct,
    },
    location: { pathname },
    pageContext: { locale },
  } = props;

  const product = { ...shopifyProduct, ...frontmatter };
  const { category, categoryHandle, gtin13, handle, ipc, title, story, variants } = product;

  const image = product.images[0].localFile.childImageSharp.gatsbyImageData.images.fallback.src;
  const canonical = `${siteUrl}${pathname}/`;

  return (
    <InterfaceContext.Consumer>
      {({
        isDesktopViewport,
        productImagesBrowserStatus,
        productImageFeatured,
        toggleProductImagesBrowser,
        setCurrentProductImages,
      }) => (
        <>
          <Helmet>
            <title>{title}</title>
            <meta name="description" content={story.replace(/"/gi, '&quot;')} />
            <meta property="og:url" content={`${canonical}`} />
            <meta property="og:locale" content={locale} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={story.replace(/"/gi, '&quot;')} />
            <meta property="og:image" content={`${siteUrl}${image}`} />
            <meta property="og:image:alt" content={title} />
            <meta property="og:image:width" content="600" />
            <meta property="og:image:height" content="600" />
            <script type="application/ld+json">
              {`{
                "@context": "https://schema.org/",
                "@type": "Product",
                "name": "${title.replace(/"/gi, '\\"')}",
                "image": [
                  ${product.images
                    .map(
                      (i) =>
                        `"${siteUrl}${i.localFile.childImageSharp.gatsbyImageData.images.fallback.src}"`
                    )
                    .join(',')}
                 ],
                "category": "${category}",
                "description": "${story.replace(/"/gi, '\\"')}",
                "weight": "${variants[0].weight}g",
                "sku": "${ipc}",
                "gtin13": "${gtin13}",
                "brand": {
                  "@type": "Brand",
                  "name": "LockCha"
                },
                "offers": {
                  "@type": "Offer",
                  "url": "${`${siteUrl}/products/${categoryHandle}/${handle}`}",
                  "priceCurrency": "HKD",
                  "price": "${variants[0].price}",
                  "itemCondition": "https://schema.org/NewCondition",
                  "availability": "https://schema.org/${
                    variants[0].availableForSale ? 'InStock' : 'SoldOut'
                  }",
                  "seller": {
                    "@type": "Organization",
                    "name": "LockCha Tea Shop Ltd."
                  }
                }
              }`}
            </script>
          </Helmet>
          <ProductPage
            isDesktopViewport={isDesktopViewport}
            product={product}
            productImageFeatured={productImageFeatured}
            productImagesBrowserStatus={productImagesBrowserStatus}
            setCurrentProductImages={setCurrentProductImages}
            toggleProductImagesBrowser={toggleProductImagesBrowser}
          />
        </>
      )}
    </InterfaceContext.Consumer>
  );
};

ProductPageTemplate.propTypes = {
  data: object,
  pageContext: object,
  location: object,
};

export default ProductPageTemplate;

export const query = graphql`
  query TeaPageTemplate($handle: String!, $locale: String!) {
    mdx(frontmatter: { handle: { eq: $handle }, locale: { eq: $locale } }) {
      frontmatter {
        ...ProductSpecs
        ipc
        gtin13
        title
        tastingNotes
        story
        images {
          altText
          localFile {
            id
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 800)
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
    shopifyProduct(handle: { eq: $handle }) {
      id
      title
      handle
      variants {
        shopifyId
        title
        price
        availableForSale
        weight
      }
      ...ShopifyProductSpecs
    }
  }
`;
