import { FormattedMessage } from 'gatsby-plugin-react-intl';
/** @jsx jsx */
import { Text, jsx } from 'theme-ui';

const ShippingNotice = (props) => (
  <Text
    sx={{
      bg: 'notice',
      px: 3,
      py: 2,
      my: [0, 0],
      fontSize: 2,
      textAlign: 'center',
      whiteSpace: 'pre-line',
    }}
    {...props}
  >
    <FormattedMessage id="home.shippingFees" />
  </Text>
);

export default ShippingNotice;
