/** @jsx jsx */
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import { Heading, Image, Paragraph, jsx } from 'theme-ui';
import { graphql } from 'gatsby';

import { breakpoints, colors, spacing } from '../../utils/styles';

const ProductSpecsRoot = styled(`div`)`
  padding: 0 ${spacing.md}px;
  flex-grow: 1;

  @media (min-width: ${breakpoints.desktop}px) {
    padding: 0 ${spacing.xl}px ${spacing.sm}px;
  }
`;

const Price = styled(`div`)`
  color: ${colors.brand};
  font-weight: 500;
  letter-spacing: -0.02em;

  span {
    color: ${colors.textLight};
  }
`;

const ProductSpecs = (props) => {
  const {
    product: {
      brewingTemperature,
      brewingDuration,
      category,
      categoryHandle,
      region,
      title,
      story,
      tastingNotes,
      variants: [variant],
    },
  } = props;

  const { compareAtPrice, price, weight } = variant;
  const teaColor = categoryHandle.split('-')[0];

  return (
    <ProductSpecsRoot>
      <Heading as="h1" variant="h1" sx={{ mt: [4, 0], mb: [0, 0] }}>
        {title}
      </Heading>
      <Paragraph sx={{ mb: [22, 22], whiteSpace: 'pre-line' }}>{story}</Paragraph>
      <Paragraph sx={{ whiteSpace: 'pre-line' }}>{tastingNotes}</Paragraph>
      <Heading>
        <FormattedMessage id="productSpecs.details" />
      </Heading>
      <ul sx={{ listStyleType: 'none', paddingInlineStart: 0 }}>
        {categoryHandle !== 'beer' && categoryHandle !== 'cold-brew-tea' && (
          <li>
            <FormattedMessage id="productSpecs.category" />
            <Image
              src={`/images/tea/${teaColor}-tea.png`}
              sx={{
                width: 18,
                height: 18,
                ml: 1,
                mr: 1,
                verticalAlign: 'text-top',
              }}
              alt={category}
            />
            {category}
          </li>
        )}
        {region && (
          <li>
            <FormattedMessage id="productSpecs.region" />
            {region}
          </li>
        )}
        {weight > 0 && (
          <li>
            <FormattedMessage id="productSpecs.weight" />
            {weight}
            <FormattedMessage id="*.gram" />
          </li>
        )}
        {brewingTemperature != null && (
          <li>
            <FormattedMessage id="productSpecs.brewingTemperature" />
            {brewingTemperature}°C
          </li>
        )}
        {brewingDuration != null && (
          <li>
            <FormattedMessage id="productSpecs.brewingDuration" />
            {brewingDuration} <FormattedMessage id="productSpecs.seconds" />
          </li>
        )}
      </ul>
      <Price>
        <span>
          <FormattedMessage id="hkd" />
        </span>{' '}
        {compareAtPrice && <s>${compareAtPrice}</s>} ${price}
      </Price>
      {categoryHandle === 'beer' && (
        <p sx={{ px: 3, py: 2, bg: 'alert', fontSize: 2 }}>
          <FormattedMessage id="productPage.ageWarning" />
          <br />
          <FormattedMessage id="productPage.shippingWarning" />
        </p>
      )}
    </ProductSpecsRoot>
  );
};

ProductSpecs.propTypes = {
  product: PropTypes.object.isRequired,
};

export default ProductSpecs;

export const query = graphql`
  fragment ProductSpecs on MdxFrontmatter {
    brewingDuration
    brewingTemperature
    category
    categoryHandle
    region
    title
    story
    tastingNotes
  }
  fragment ShopifyProductSpecs on ShopifyProduct {
    variants {
      compareAtPrice
      price
      weight
    }
  }
`;
